define('ember-busy-blocker/services/busy', ['exports', 'ember'], function (exports, _ember) {
  var Evented = _ember['default'].Evented;
  var Service = _ember['default'].Service;
  exports['default'] = Service.extend(Evented, {
    /**
     * Triggers hide of the busy blocker.
     * @method hide
     * @return {void}
     */
    hide: function hide() {
      this.trigger('hide');
    },

    /**
     * Triggers showing the busy blocker, passing along optinal options.
     * @method show
     * @param {Object} [options] An optional object containing options for the loading indicator
     *                           such as a custom message.
     * @return {void}
     */
    show: function show(options) {
      this.trigger('show', options);
    }
  });
});