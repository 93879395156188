define('bootstrap-tooltip-activator/mixins/bootstrap-tooltip-activator', ['exports', 'ember'], function (exports, _ember) {

	var Em = _ember['default'];

	var $ = _ember['default'].$;
	var Mixin = _ember['default'].Mixin;
	var on = _ember['default'].on;
	var _Ember$run = _ember['default'].run;
	var bind = _Ember$run.bind;
	var scheduleOnce = _Ember$run.scheduleOnce;
	exports['default'] = Mixin.create({
		timer: null,
		interval: 500,
		isActive: true,
		cachedElements: null,

		setCachedElements: on('init', function () {
			this.set('cachedElements', Em.A([]));
		}),

		initTooltips: on('didInsertElement', function () {
			scheduleOnce('afterRender', this, function () {
				var cachedElements = this.get('cachedElements');
				if (this.get('isActive') && this.get('_state') === 'inDOM') {
					this.clearTooltipsOfDetachedElements();
					this.$('[data-toggle="tooltip"]').toArray().forEach(function (el) {
						var $el = $(el),
						    tooltipData = $el.data()['bs.tooltip'];
						if (!tooltipData) {
							$el.tooltip();
							cachedElements.pushObject($el);
						}
					}, this);
					if (!this.get('timer')) {
						this.set('timer', setInterval(bind(this, this.initTooltips), this.get('interval')));
					}
				}
			});
		}),

		clearTooltipsOfDetachedElements: function clearTooltipsOfDetachedElements() {
			var cachedElements = this.get('cachedElements'),
			    zombies = cachedElements.filter(function (el) {
				return !$.contains(document, el[0]);
			});

			zombies.forEach(function (el) {
				el.tooltip('destroy');
				cachedElements.removeObject(el);
				el.remove();
			});
		},

		destroyTooltips: on('willDestroyElement', function () {
			this._super();
			this.clearTooltipsOfDetachedElements();
			this.set('isActive', false);
			clearInterval(this.get('timer'));
			this.set('timer', null);
			var $tooltips = this.$('[data-toggle="tooltip"]');
			if ($tooltips && typeof $tooltips.tooltip === 'function') {
				this.$('[data-toggle="tooltip"]').tooltip('destroy');
			}
		})

	});
});