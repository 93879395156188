define('ember-inline-edit/components/ember-inline-edit', ['exports', 'ember', 'ember-inline-edit/templates/components/ember-inline-edit'], function (exports, _ember, _emberInlineEditTemplatesComponentsEmberInlineEdit) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  var on = _ember['default'].on;
  var run = _ember['default'].run;
  var Logger = _ember['default'].Logger;
  var info = Logger.info;
  exports['default'] = _ember['default'].Component.extend({
    layout: _emberInlineEditTemplatesComponentsEmberInlineEdit['default'],
    classNames: ['ember-inline-edit'],
    classNameBindings: ['isEditing:is-editing'],

    textFields: ['search', 'url', 'text', 'phone', 'email', 'number'],
    textAreaFields: ['textarea'],

    isEditing: false,

    field: 'text',
    value: null,
    placeholder: 'Not Provided',
    saveLabel: 'Save',
    fieldWidth: null,

    valueIsEmpty: computed.empty('value'),

    setup: on('didInsertElement', function () {
      this._handleClick = this._handleClick.bind(this);
      this._handleKeyup = this._handleKeyup.bind(this);
      this._setupEventHandlers();
    }),

    _setupEventHandlers: function _setupEventHandlers() {
      _ember['default'].$(document).on('click', this._handleClick);
      _ember['default'].$(this.element).on('keyup', '.ember-inline-edit-input', this._handleKeyup);
    },

    _handleClick: function _handleClick(e) {
      var _this = this;

      var isEditing = get(this, 'isEditing');
      var editor = _ember['default'].$(this.element);
      var target = _ember['default'].$(e.target);
      var isInside = editor.is(target) || editor.has(target).length > 0;

      if (isInside && !isEditing) {
        var _ret = (function () {
          if (get(_this, 'showEditButton')) {
            return {
              v: undefined
            };
          }
          var width = _ember['default'].String.htmlSafe('width: ' + (editor.width() + 2) + 'px');
          _ember['default'].run(_this, function () {
            this.set('fieldWidth', width);
          });
          _this.send('startEditing', e);
        })();

        if (typeof _ret === 'object') return _ret.v;
      } else if (!isInside && isEditing) {
        this.send('close');
      }
    },

    _handleKeyup: function _handleKeyup(e) {
      var isEditing = get(this, 'isEditing');
      var isEnter = e.which === 13 || e.keyCode === 13;
      var isEsc = e.which === 27 || e.keyCode === 27;

      if (!isEditing) {
        return;
      }

      if (isEnter) {
        this.send('save');
      } else if (isEsc) {
        this.send('close');
      }
    },

    _focusOnInput: function _focusOnInput() {
      run.next(function () {
        _ember['default'].$('.ember-inline-edit-input').focus();
      });
    },

    _teardown: on('willDestroyElement', function () {
      _ember['default'].$(document).off('click', this._handleClick);
      _ember['default'].$(this.element).off('keyup', '.ember-inline-edit-input', this._handleKeyup);
    }),

    actions: {
      save: function save() {
        info('[ember-inline-edit] Got the `onSave` action');
        this.sendAction('onSave', this.get('value'));
        _ember['default'].run(this, function () {
          set(this, 'isEditing', false);
        });
      },

      startEditing: function startEditing(e) {
        e.stopPropagation();
        info('[ember-inline-edit] Got the `startEditing` action');
        _ember['default'].run(this, function () {
          set(this, 'isEditing', true);
        });
        this._focusOnInput();
      },

      close: function close() {
        info('[ember-inline-edit] Got the `onClose` action');
        this.sendAction('onClose');
        _ember['default'].run(this, function () {
          set(this, 'isEditing', false);
        });
      },

      setValue: function setValue(value) {
        _ember['default'].run(this, function () {
          set(this, 'value', value);
        });
      }
    }
  });
});