define('ember-highlight/components/highlight-terms/component', ['exports', 'ember', 'ember-highlight/components/highlight-terms/template'], function (exports, _ember, _emberHighlightComponentsHighlightTermsTemplate) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var run = _ember['default'].run;

  var HighlightTerm = _ember['default'].Component.extend({
    layout: _emberHighlightComponentsHighlightTermsTemplate['default'],
    classNames: ['highlight-terms'],

    didReceiveAttrs: function didReceiveAttrs() {
      var term = this.get('term');
      var options = this.getProperties('caseSensitive', 'wordsOnly');

      run.scheduleOnce('afterRender', this, function () {
        this.unhighlight();
        this.highlight(term, options);
      });
    },

    willDestroyElement: function willDestroyElement() {
      this.unhighlight();
    },

    highlight: function highlight(term, options) {
      if (term) {
        if (Array.isArray(term)) {
          term = term.reduce(function (all, item) {
            if (item !== undefined) {
              if (Array.isArray(item)) {
                var _all;

                all = (_all = all).concat.apply(_all, _toConsumableArray(item));
              } else {
                all.push(item);
              }
            }

            return all;
          }, []);
        }

        if (this.$()) {
          this.$().highlight(term, options);
        }
      }
    },

    unhighlight: function unhighlight() {
      if (this.$()) {
        this.$().unhighlight();
      }
    }
  });

  HighlightTerm.reopenClass({
    positionalParams: 'term'
  });

  exports['default'] = HighlightTerm;
});