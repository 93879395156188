define('ember-busy-blocker/components/busy-blocker', ['exports', 'ember', 'ember-busy-blocker/templates/components/busy-blocker'], function (exports, _ember, _emberBusyBlockerTemplatesComponentsBusyBlocker) {
  var Component = _ember['default'].Component;
  var inject = _ember['default'].inject;

  // Animation duration in milliseconds
  var ANIMATION_DURATION = 300;
  var DEFAULT_MESSAGE = 'Loading...';

  exports['default'] = Component.extend({
    // Dependencies
    // ------------------------
    busy: inject.service(),

    // Properties
    // ------------------------
    classNames: ['busy-blocker'],
    animationElements: null,
    layout: _emberBusyBlockerTemplatesComponentsBusyBlocker['default'],
    message: null,

    // Hooks
    // ------------------------
    didInsertElement: function didInsertElement() {
      // Convert animation duration ms to css string value
      var duration = ANIMATION_DURATION / 1000 + 's';
      var busy = this.get('busy');

      // Hide immediately
      this.$().css('display', 'none');

      this.set('animationElements', this.$('.busy-blocker__bg, .busy-blocker__content'));
      // Set the animation duration on the backdrop element
      this.$('.busy-blocker__bg').css('animation-duration', duration);

      busy.on('hide', this, this._hide);
      busy.on('show', this, this._show);
    },

    willDestroyElement: function willDestroyElement() {
      var busy = this.get('busy');

      busy.off('hide', this, this._hide);
      busy.off('show', this, this._show);
    },

    // Functions
    // ------------------------
    /**
     * Hide the busy animation.
     * @method _hide
     * @private
     * @return {void}
     */
    _hide: function _hide() {
      var elements = this.get('animationElements');

      elements.removeClass('fade-in');
      elements.addClass('fade-out');

      _ember['default'].run.later(this, function hideLoading() {
        this.$().css('display', 'none');
      }, ANIMATION_DURATION);
    },

    /**
     * Show the busy animation and apply received options.
     * @param  {Object} [options] An optional object containing options for the busy animation
     *                           such as a custom message.
     * @method _show
     * @private
     * @returns {void}
     */
    _show: function _show(options) {
      var elements = this.get('animationElements');
      var message = DEFAULT_MESSAGE;

      if (options && options.message) {
        message = options.message;
      }

      this.set('message', message);

      this.$().css('display', 'block');
      elements.removeClass('fade-out');
      elements.addClass('fade-in');
    }
  });
});