define('npm:bluebird', function(){ return { 'default': require('bluebird')};})
define('npm:handlebars', function(){ return { 'default': require('handlebars')};})
define('npm:handlebars.moment', function(){ return { 'default': require('handlebars.moment')};})
define('npm:is-numeric', function(){ return { 'default': require('is-numeric')};})
define('npm:mapbox.js', function(){ return { 'default': require('mapbox.js')};})
define('npm:round-to', function(){ return { 'default': require('round-to')};})
define('npm:starts-with', function(){ return { 'default': require('starts-with')};})
define('npm:underscore', function(){ return { 'default': require('underscore')};})
define('npm:url-parse', function(){ return { 'default': require('url-parse')};})
define('npm:validator', function(){ return { 'default': require('validator')};})
define('npm:xss', function(){ return { 'default': require('xss')};})