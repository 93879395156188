define('ember-set-body-class/components/set-body-class', ['exports', 'ember-set-body-class/util/get-dom'], function (exports, _getDom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);
      var body = (0, _getDom.default)(this).body;
      var attr = body.getAttribute('class');
      var name = this.get('name');
      if (attr) {
        var classList = attr.split(/\s+/);
        if (classList.indexOf(name) === -1) {
          classList.push(name);
          body.setAttribute('class', classList.join(' '));
        }
      } else {
        body.setAttribute('class', name);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      var body = (0, _getDom.default)(this).body;
      var attr = body.getAttribute('class');
      var name = this.get('name');
      if (attr) {
        var classList = attr.split(/\s+/);
        var index = classList.indexOf(name);
        if (index !== -1) {
          classList.splice(index, 1);
          body.setAttribute('class', classList.join(' '));
        }
      }
    }
  }).reopenClass({
    positionalParams: ['name']
  });
});